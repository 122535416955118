html {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}
  
::-webkit-scrollbar-track {
  width: 5px;
  background: #1f3898;
  border-radius: 3px;
}
/* #dedede40 */
/* #0d0469; */
::-webkit-scrollbar-thumb {
  background :#ababab; 
  border-radius: 3px;
  min-height: 50px;
}

#root {
  min-height: 100vh;
}

#loader {
  background-color: #000000;
  opacity: 1;
  pointer-events: none;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 40vw;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 33vw;
    left: -webkit-calc(50% - 7vw);
    left: -moz-calc(50% - 7vw);
    left: calc(50% - 7vw);
    width: 14vw;
    height: 14vw;
    opacity: 0;
  }
}